<template>
  <el-form-item
    :label="$t('pages.registers.edit.form.properties.default_favourite.label')"
  >
    <remote-search-select
      :model-value="modelValue.default_favourite"
      resource="favourites"
      class="w-full"
      fetch-handler="getAll"
      :disabled="readonly"
      :modify-query="
        (q) => ({
          query: {
            limit: 50,
            deleted: false,
            q
          }
        })
      "
      @update:modelValue="handleChange"
    />
  </el-form-item>
</template>

<script>
import RemoteSearchSelect from '@/components/select/remote-search'

export default {
  components: {
    RemoteSearchSelect
  },

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleChange(v) {
      this.$emit('update:modelValue', {
        ...this.modelValue,
        default_favourite: v || null
      })
    }
  }
}
</script>
