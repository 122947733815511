import safeGet from 'just-safe-get'
import { useStore } from 'vuex'
export default function () {
  const store = useStore()
  function onboardingConfirmed(property) {
    return (
      safeGet(
        store.state.Config.clientAccountConfiguration,
        `onboarding.stages.${property}`
      ) !== false
    )
  }

  async function modifyOnboarding(property) {
    store.dispatch('Config/setClientAccountConfigurationValue', {
      path: `onboarding.stages.${property}`,
      value: true
    })
    store.dispatch('Config/checkOnboardingCompleted')
    store.dispatch('Config/prepareData')
    await store.dispatch('Config/saveClientAccountConfiguration')
  }

  return {
    onboardingConfirmed,
    modifyOnboarding
  }
}
