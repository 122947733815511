export function getTypesLabels(vm = { $t: (f) => f }) {
  return {
    cash: vm.$t('pages.payment_options.edit.form.properties.types.cash.label'),
    invoice: vm.$t(
      'pages.payment_options.edit.form.properties.types.invoice.label'
    ),
    card: vm.$t('pages.payment_options.edit.form.properties.types.card.label'),
    gift_card: vm.$t(
      'pages.payment_options.edit.form.properties.types.gift_card.label'
    ),
    terminal_gift_card: vm.$t(
      'pages.payment_options.edit.form.properties.types.terminal_gift_card.label'
    ),
    undefined: vm.$t(
      'pages.payment_options.edit.form.properties.types.undefined.label'
    ),
    card_opi: vm.$t(
      'pages.payment_options.edit.form.properties.types.card_opi.label'
    ),
    card_concardis: vm.$t(
      'pages.payment_options.edit.form.properties.types.card_concardis.label'
    ),
    card_adyen: vm.$t(
      'pages.payment_options.edit.form.properties.types.card_adyen.label'
    ),
    card_tim: vm.$t(
      'pages.payment_options.edit.form.properties.types.card_tim.label'
    ),
    sumup: vm.$t('pages.payment_options.edit.form.properties.types.sumup.label')
  }
}

export function getCardCircuitsTypes(vm = { $t: (f) => f }) {
  return [
    {
      value: 'americanExpress',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.american_express.label'
      ),
      matchers: ['amex', 'american express', 'americanexpress']
    },
    {
      value: 'diners',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.diners.label'
      ),
      matchers: ['diners club', 'dinersclub']
    },
    {
      value: 'jcb',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.jcb.label'
      ),
      matchers: ['JCB']
    },
    {
      value: 'maestro',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.maestro.label'
      ),
      matchers: ['maestro']
    },
    {
      value: 'maestroCh',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.maestro_ch.label'
      ),
      matchers: ['Maestro-CH']
    },
    {
      value: 'masterCard',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.masterCard.label'
      ),
      matchers: ['mastercard', 'mc', 'Master Card']
    },
    {
      value: 'visa',
      label: vm.$t(
        'pages.payment_options.edit.form.properties.card_circuits.visa.label'
      ),
      matchers: ['visa', 'Supercard VISA']
    }
  ]
}
