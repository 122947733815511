<template>
  <th-page-wrapper :actions="actions" @save="handleSubmit">
    <edit-form ref="form" class="p-8" />
  </th-page-wrapper>
</template>

<script>
import EditForm from './components/form'

export default {
  components: {
    EditForm
  },
  metaInfo() {
    return {
      title: this.$t('common.resource.register.singular')
    }
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'resources:registers',
        returnedPath: { name: 'resources-registers-all' },
        excludeButtons: ['delete'],
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm('form')
    }
  }
}
</script>
