<template>
  <div v-loading="loading" class="relative text-center">
    <!-- tenant data check failed -->
    <template v-if="!tenantIsValid">
      <h3 class="inline-flex items-center">
        <el-icon class="text-th-red mr-2"><CloseBold /></el-icon>
        {{ $t('pages.registers.fiskaly.data-check.headline') }}
      </h3>
      <p>{{ invalidDataText }}</p>
      <div class="inline-flex">
        <el-button class="mr-2" @click="handleModalClose">
          {{ $t('pages.registers.fiskaly.buttons.cancel') }}
        </el-button>
        <el-button class="ml-2" type="primary" @click="setupTenantInformation">
          {{ $t('pages.registers.fiskaly.buttons.ok') }}
        </el-button>
      </div>
    </template>
    <!-- Run fiskaly process -->
    <run-fiskaly
      v-if="tenantIsValid"
      :branch="branch"
      :register-id="registerId"
      :branch-config="branchConfig"
      @close-enabled="$emit('close-enabled', $event)"
      @finish="finishSetup"
    />
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import get from 'just-safe-get'
import isEmpty from 'just-is-empty'
import typeOf from 'just-typeof'
import { mapGetters } from 'vuex'

import RunFiskaly from './run-fiskaly'

export default {
  name: 'FiskalyModal',
  components: {
    RunFiskaly
  },
  props: {
    branchId: {
      type: String,
      required: true
    },
    registerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      branch: {},
      branchConfig: {}
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    tenant() {
      return get(this.clientAccountConfiguration, 'tenant')
    },
    tenantIsValid() {
      const multiTenantAddress = this.isMultiTenantMode
        ? this.branch?.addresses?.find(({ type }) => type === 'billing')
        : undefined

      const tenantData = this.isMultiTenantMode
        ? { ...this.branchConfig?.tenant, address: multiTenantAddress }
        : this.tenant
      return !this.findInvalidProps(tenantData)
    },
    isMultiTenantMode() {
      return (
        get(this.clientAccountConfiguration, 'metadata.fiskaly_mode') ===
        'multitenant'
      )
    },
    invalidDataText() {
      return this.isMultiTenantMode
        ? this.$t('pages.registers.fiskaly.data-check.multitenant.description')
        : this.$t('pages.registers.fiskaly.data-check.description')
    }
  },
  created() {
    if (this.isMultiTenantMode && this.branchId) {
      this.fetchBranchConfig()
      this.fetchBranch()
    }
  },
  methods: {
    findInvalidProps(obj) {
      if (!obj || isEmpty(obj)) {
        return true
      }

      let hasInvalidProps = false
      Object.keys(obj).forEach((key) => {
        if (typeOf(obj[key]) === 'object') {
          hasInvalidProps = this.findInvalidProps(obj[key])
        }

        if (obj[key] === undefined || obj[key] === '') {
          hasInvalidProps = true
        }
      })

      return hasInvalidProps
    },
    setupTenantInformation() {
      this.handleModalClose()
      if (this.isMultiTenantMode) {
        return this.$router.push({
          name: 'resources-branches-edit',
          params: { id: this.branchId },
          query: { tab: 'addresses' }
        })
      }
      this.$router.push({ name: 'account' })
    },
    finishSetup() {
      this.handleModalClose()
      this.$emit('finish')
    },
    async fetchBranchConfig() {
      this.loading = true
      try {
        if (!this.branchId) {
          throw new TypeError(
            'missing valid branch for multitenant fiskaly setup'
          )
        }

        const { data } = await th
          .configurations()
          .getAll({ owner: this.branchId })

        this.branchConfig = data[0]
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    async fetchBranch() {
      this.loading = true
      if (!this.branchId) {
        throw new TypeError(
          'missing valid branch for multitenant fiskaly setup'
        )
      }
      try {
        const { data } = await th.branches().get(this.branchId)
        this.branch = data
      } catch (err) {
        this.$logException(err, { trackError: false })
      } finally {
        this.loading = false
      }
    },
    handleModalClose() {
      this.$thModal.hide('fiskaly')
    }
  }
}
</script>

<style scoped>
.el-icon-error {
  font-size: 24px;
}
</style>
