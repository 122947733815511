<template>
  <div class="qr-code-viewer w-full">
    <th-input-title
      v-if="label"
      :title="label"
      :class="{ italic: !isWhiteLabel }"
    />
    <div
      class="border rounded border-dashed border-color-hover w-full flex justify-center items-center"
      :style="{ height }"
    >
      <img
        v-show="showQrImage"
        class="h-full w-full object-contain"
        :src="imageSrc"
      />
    </div>
  </div>
</template>

<script>
import { handleQR } from '@/components/barcodes/helpers'
import { isUnifiedCommerce } from '@/constants'

export default {
  name: 'QrCodeViewer',
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: null
    }
  },
  data() {
    return { imageSrc: '' }
  },
  computed: {
    showQrImage() {
      return !!this.modelValue
    },
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      /**
       * Update the bar code when props changed.
       */
      handler() {
        if (this.$el) {
          this.generate()
        }
      }
    }
  },

  mounted() {
    this.generate()
  },

  methods: {
    async generate() {
      if (this.showQrImage) {
        this.imageSrc = await handleQR(this.modelValue)
      }
    }
  }
}
</script>
