<template>
  <el-form ref="configurationsForm" :model="form.features">
    <!-- balcklisted payment options -->
    <el-form-item prop="blacklisted_payment_types">
      <th-input-title
        :title="
          $t(
            'pages.registers.edit.form.properties.blacklisted_payment_types.label'
          )
        "
        :info="
          $t(
            'pages.registers.edit.form.properties.blacklisted_payment_types.text'
          )
        "
      />
      <el-select
        id="blacklisted_payment_types"
        v-model="form.payments.blacklisted_payment_types"
        multiple
        class="w-full"
        :popper-append-to-body="false"
        :disabled="readonly"
      >
        <el-option
          v-for="payment in paymentTypes"
          :key="payment.value"
          :label="payment.label"
          :value="payment.value"
        />
      </el-select>
    </el-form-item>
    <!-- Table management -->
    <el-form-item>
      <div class="flex items-center h-10">
        <th-popover
          :text="
            $t('pages.registers.edit.form.properties.table_management.tooltip')
          "
          class="mr-2"
        />
        <el-switch
          v-model="form.features.gastro_tables.enabled"
          :active-text="
            $t('pages.registers.edit.form.properties.table_management.text')
          "
          :disabled="readonly"
        />
      </div>
    </el-form-item>
  </el-form>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import safeGet from 'just-safe-get'
import { getTypesLabels } from '@/views/accounting/payment-options/helpers'

const genInitialFeatures = () => ({
  features: {
    gastro_tables: {
      enabled: false
    }
  },
  payments: {
    blacklisted_payment_types: null
  }
})

export default {
  props: {
    branch: {
      type: Object,
      required: false,
      default: () => {}
    },
    branchError: {
      type: Boolean,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false
    }
  },

  data() {
    return {
      isNew: true,
      form: genInitialFeatures(),
      typesLabels: getTypesLabels(this),
      payload: {},
      loading: false,
      pristineForm: null
    }
  },

  computed: {
    paymentTypes() {
      return [
        { value: 'cash', label: this.typesLabels.cash },
        { value: 'card', label: this.typesLabels.card },
        { value: 'invoice', label: this.typesLabels.invoice },
        { value: 'gift_card', label: this.typesLabels.gift_card },
        {
          value: 'terminal_gift_card',
          label: this.typesLabels.terminal_gift_card
        }
      ]
    }
  },

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    async fetch(id) {
      this.loading = true
      this.pristineForm = this.$deepClone(this.form)

      try {
        const { configurations = [] } = await this.$resourceFetch({
          resource: 'configurations',
          query: { owner: id }
        })

        const resp = configurations[0]
        if (resp && resp.id) {
          this.handlePayload(resp)
        }
        this.isNew = !resp
      } catch (err) {
        return
      } finally {
        this.loading = false
      }
    },

    submitForm(registerId, registerName) {
      this.loading = true

      const body = this.makeHandleableConfigurationsBody(this.form)

      if (this.isNew) {
        this.create({ payload: body, registerId, registerName })
      } else {
        this.alter(this.payload.id, body)
      }
    },

    async alter(id, payload) {
      const errorMessage = this.$t(
        'pages.registers.edit.form.register_configurations.messages.edit_fail'
      )
      let data
      try {
        const resp = await th.configurations().put(id, payload)
        data = resp.data || {}
      } catch (err) {
        return this.$logException(err, { message: errorMessage })
      } finally {
        this.loading = false
      }

      if (data.id) {
        this.handlePayload(data)
        this.$emit('handled-item')
        this.$emit('altered-item')
      }
    },

    async create({ payload, registerId, registerName }) {
      const errorMessage = this.$t('common.error.action.create.single', {
        resource: this.$t('common.resource.configuration.singular')
      })
      this.$log.debug('register-configurations-edit: will create resource')

      let data
      try {
        const resp = await th.configurations().create({
          ...payload,
          owner: registerId,
          name: registerName || `Register Config ${registerId}`
        })
        data = resp.data || {}
      } catch (err) {
        this.$logException(err, { message: errorMessage })
      } finally {
        this.loading = false
      }

      this.$log.debug('register-configurations-edit: have created resource')

      if (data.id) {
        this.$message({
          type: 'success',
          message: this.$t('common.success.action.create.single', {
            resource: this.$t('common.resource.configuration.singular')
          })
        })
      } else {
        this.$message({
          type: 'error',
          message: errorMessage
        })
      }
    },

    validate(cb) {
      this.$refs.configurationsForm.validate((valid) => cb(valid))
    },

    makeHandleableConfigurationsBody(form) {
      return { ...form }
    },

    handlePayload(item) {
      this.payload = { ...item }
      const defaults = genInitialFeatures()

      this.form.features = {
        ...defaults.features,
        ...safeGet(item, 'features')
      }
      this.form.payments = {
        ...defaults.payments,
        ...safeGet(item, 'payments')
      }

      this.pristineForm = this.$deepClone(this.form)

      this.$log.debug('register configuration form', this.form)
      this.$log.debug('register configuration payload', this.payload)
    },

    resetData() {
      if (this.isNew) {
        this.form = genInitialFeatures()
        this.pristineForm = this.$deepClone(this.form)
      } else {
        this.handlePayload(this.payload)
      }
    }
  }
}
</script>

<style scoped></style>
