<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules">
      <th-wrapper
        v-loading="loading"
        :title="$t('common.titles.general_info.title')"
        :subtitle="$t('pages.registers.edit.subtitle')"
        body-class="p-8 pb-3"
      >
        <!-- Actions -->
        <template #actions>
          <div class="actions flex items-center">
            <!-- client version -->
            <div v-if="version" class="mr-4" v-text="version" />

            <!-- TSE -->
            <div v-if="!isReadOnly">
              <!-- TSE configured -->
              <div
                v-if="tssConfigured"
                class="flex items-center text-sm text-green-600"
              >
                <el-icon class="mr-1"><Check /></el-icon>
                <span>{{ $t('pages.registers.edit.form.tss.label') }}</span>
              </div>

              <!-- Add TSE  -->
              <el-button
                v-else
                size="small"
                type="primary"
                plain
                @click="startTss"
              >
                {{ $t('pages.registers.edit.form.buttons.startTSS') }}
              </el-button>
            </div>
          </div>
        </template>
        <!-- Body -->
        <el-row :gutter="20">
          <el-col :lg="6" :md="8">
            <!-- Register Name -->
            <el-form-item
              :label="$t('pages.registers.edit.form.properties.name.label')"
              prop="name"
            >
              <el-input id="name" v-model="form.name" :readonly="isReadOnly" />
            </el-form-item>

            <!-- Cost Center -->
            <el-form-item prop="cost_center">
              <th-input-title
                :title="
                  $t('pages.registers.edit.form.properties.cost_center.label')
                "
                :info="
                  isDatevEnabled
                    ? $t(
                        'pages.registers.edit.form.properties.cost_center.datev.text'
                      )
                    : null
                "
              />
              <el-input v-model="form.cost_center" :readonly="isReadOnly" />
            </el-form-item>
            <!-- Configurations -->
            <configurations
              ref="configurationsForm"
              :branch="branchPayload"
              :branch-error="branchError"
              :readonly="isReadOnly"
            />
          </el-col>

          <el-col :lg="6" :md="8">
            <!-- Register Number -->
            <el-form-item
              :label="
                $t('pages.registers.edit.form.properties.register_number.label')
              "
              prop="register_number"
            >
              <el-input
                id="register_number"
                v-model="form.register_number"
                :disabled="!isNew"
                :readonly="isReadOnly"
              />
            </el-form-item>

            <!-- Branch -->
            <el-form-item
              prop="branch"
              :label="$t('pages.registers.edit.form.properties.branch.label')"
            >
              <remote-search-select
                id="branch"
                v-model="form.branch"
                class="w-full"
                :computed-fields="['branch_number', 'name']"
                :resource-query="{ query: { deleted: false } }"
                resource="branches"
                :disabled="hasBranch || isReadOnly"
              />
            </el-form-item>
            <!-- Default Tileset -->
            <favourites
              ref="favouritesForm"
              v-model="form"
              :readonly="isReadOnly"
            />
          </el-col>
          <el-col :lg="12" :md="12">
            <!-- Scan QR-Code to bind your register -->
            <qr-code-viewer
              :model-value="qrData"
              height="207px"
              :label="
                $t('pages.registers.edit.form.properties.register_qrcode.label')
              "
            />
          </el-col>
        </el-row>
      </th-wrapper>
    </el-form>

    <th-modal name="fiskaly" width="400">
      <fiskaly
        class="py-4 px-16"
        :branch-id="form.branch"
        :register-id="registerId"
        @close-enabled="handleModalClose"
        @finish="finishFiscalisation"
        @close="handleModalClose"
      />
    </th-modal>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import pick from 'just-pick'
import Configurations from './more/configurations'
import Favourites from './more/favourites'
import Fiskaly from './fiskaly'

import { isEmptyArray, isEmptyObject } from '@/utils/objects'
import { isEmptyString } from '@/utils/strings'
import safeGet from 'just-safe-get'
import onboardingMixin from '@/mixins/onboarding'
import QrCodeViewer from '@/components/barcodes/qr-code-viewer'
import RemoteSearchSelect from '@/components/select/remote-search'
import { isUnifiedCommerce } from '@/constants/index'

function genInitialData() {
  return {
    name: null,
    description: null,
    register_number: null,
    cost_center: null,
    branch: null,
    default_favourite: null,
    device_configuration: null
  }
}

function makeHandleableregistersBody(payload, form) {
  const cleanedPayload = pick(payload, Object.keys(form))

  // replace empty objects, emtpy arrays and empty strings with null
  return Object.keys(cleanedPayload).reduce((result, key) => {
    const isEmpty =
      isEmptyObject(cleanedPayload[key]) ||
      isEmptyArray(cleanedPayload[key]) ||
      isEmptyString(cleanedPayload[key])
    result[key] = isEmpty ? null : cleanedPayload[key]
    return result
  }, {})
}

export default {
  components: {
    Configurations,
    Favourites,
    Fiskaly,
    QrCodeViewer,
    RemoteSearchSelect
  },

  setup() {
    const { onboardingConfirmed, modifyOnboarding } = onboardingMixin()
    return {
      onboardingConfirmed,
      modifyOnboarding
    }
  },

  data() {
    function registerNumber(rule, value, callback) {
      if (value === '' || !Number.isFinite(Number(value))) {
        callback(new Error('Please enter a number'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      valid: false,
      form: genInitialData(),
      version: null,
      isModalOpen: false,
      rules: {
        name: [
          {
            required: true,
            message: this.$t('pages.registers.edit.form.rules.name.required'),
            trigger: 'blur'
          },
          {
            min: 3,
            max: 128,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 3,
              max: 128
            }),
            trigger: 'blur'
          }
        ],
        register_number: [
          {
            required: true,
            message: this.$t(
              'pages.registers.edit.form.rules.register_number.required'
            ),
            trigger: 'blur'
          },
          { validator: registerNumber, trigger: 'blur' }
        ],
        cost_center: [
          {
            max: 128,
            message: this.$t(
              'pages.customers.edit.form.field_warnings.max_length',
              { length: 128 }
            )
          }
        ],
        branch: [
          {
            required: true,
            message: this.$t('pages.registers.edit.form.rules.branch.required'),
            trigger: 'blur'
          }
        ]
      },
      payload: {},
      branchPayload: null,
      branchError: false,
      pristineForm: null,
      fiskalyCloseIsPossible: true,
      hasBranch: false
    }
  },

  computed: {
    registerId() {
      return this.$route.params.id
    },
    isNew() {
      // cheat
      if (this.$route.params.id && this.$route.params.id === 'new') return true

      return !this.$route.params.id
    },
    isReadOnly() {
      return !this.isNew && isUnifiedCommerce()
    },
    tssConfigured() {
      return safeGet(
        this.payload,
        'device_configuration.fiscal_signing_type',
        false
      )
    },
    isDatevEnabled() {
      return this.$isFeatureEnabled('datev')
    },
    qrData() {
      const branchId = this.form.branch
      if (!this.registerId || !branchId) return ''
      return JSON.stringify({ registerId: this.registerId, branchId })
    }
  },

  mounted() {
    this.pristineForm = this.$deepClone(this.form)
    if (!this.isNew) this.fetch(this.$route.params.id)
  },

  methods: {
    async fetch(id) {
      this.loading = true

      // fetch register data
      try {
        const inst = th.registers()

        this.loading = true
        const { data = {} } = await inst.get(id)

        if (data.id) {
          this.handleItem(data)
        }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t('common.error.action.read.single', {
            resource: this.$t('common.resource.register.singular')
          })
        })
      } finally {
        this.loading = false
      }

      // fetch branch data (to get currency default)
      let res
      try {
        res = await th.branches().get(this.payload.branch)
      } catch (err) {
        this.branchError = true
        return
      } finally {
        this.loading = false
      }

      if (res.data) this.branchPayload = { ...res.data }
    },
    handleItem(item) {
      this.payload = item
      this.form = {
        ...pick(item, Object.keys(this.form))
      }
      this.version = item.client_version
      this.hasBranch = !!item.branch
      this.pristineForm = this.$deepClone(this.form)

      if (this.$route.query.fiskaly === 'start' && !this.tssConfigured) {
        this.isModalOpen = true
      }
    },
    submitForm(formName) {
      this.validate('form', (valid) => {
        if (!valid) {
          return this.$message({
            type: 'warning',
            message: this.$t('common.forms.message.invalid_inputs')
          })
        }

        this.alter(this.payload.id)
      })
    },
    validate(formName = 'form', cb) {
      this.$refs[formName].validate((valid) => {
        return cb(valid)
      })
    },
    resetData(formName) {
      this.form = genInitialData()
    },
    async alter(id) {
      const payload = {
        ...this.payload,
        ...this.form,
        register_number: Number(this.form.register_number) || null
      }

      try {
        const inst = th.registers()
        this.loading = true

        const { data = {} } = await inst.put(
          payload.id,
          makeHandleableregistersBody(payload, this.form)
        )

        if (data.id) {
          this.handleItem(data)
          await this.$refs.configurationsForm.submitForm(data.id, data.name)
        }

        // Modify onboarding, in case the step is not confirmed yet
        if (!this.onboardingConfirmed('register')) {
          await this.modifyOnboarding('register')
        }

        this.$message({
          type: 'success',
          message: this.$t('common.success.action.update.single', {
            resource: this.$t('common.resource.register.singular')
          })
        })

        this.$emit('handled-item')
        this.$emit('altered-item')
      } catch (err) {
        this.$logException(err, {
          message: this.$t('common.error.action.update.single', {
            resource: this.$t('common.resource.register.singular')
          })
        })
      } finally {
        this.loading = false
      }
    },
    finishFiscalisation() {
      this.fetch(this.registerId)
    },
    startTss() {
      this.validate('form', (valid) => {
        if (valid) this.$thModal.show('fiskaly')
      })
    },
    handleModalClose() {
      this.$thModal.hide('fiskaly')
    }
  }
}
</script>

<style scoped>
.close-button {
  cursor: pointer;
  text-align: right;
  margin: 20px 20px 0 0;
  font-size: 20px;
  color: grey;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
</style>
