<template>
  <div class="relative">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      :rules="rules"
      class="h-full"
    >
      <transition-group name="fade" mode="out-in">
        <div v-if="!processDone" key="start">
          <h3>{{ $t('pages.registers.fiskaly.headline') }}</h3>
          <p>
            {{ $t('pages.registers.fiskaly.description') }}
          </p>
          <el-form-item
            prop="serial"
            :label="$t('pages.registers.fiskaly.serial_number.label')"
          >
            <el-input
              id="serial"
              v-model="form.serial"
              :placeholder="$t('pages.registers.fiskaly.serial_number.label')"
              @keypress.enter="preventEnterKey($event)"
            />
          </el-form-item>
          <div class="text-right">
            <el-button
              type="primary"
              :disabled="buttonIsDisabled"
              @click="submit()"
            >
              {{ $t('pages.registers.fiskaly.buttons.save') }}
            </el-button>
          </div>
        </div>
        <div v-else key="finish">
          <h3 class="inline-flex items-center">
            <el-icon :class="messageIconClass" class="mr-1" />
            {{ messageHeadlineKey }}
          </h3>
          <p>
            {{ messageTextKey }}
          </p>
          <el-button class="ml-2" type="primary" @click="saveTSE()">
            {{ $t('pages.registers.fiskaly.buttons.ok') }}
          </el-button>
        </div>
      </transition-group>
    </el-form>
  </div>
</template>

<script>
import get from 'just-safe-get'
import { mapGetters } from 'vuex'

export default {
  name: 'SerialInput',
  props: {
    branch: {
      type: Object,
      required: true
    },
    branchConfig: {
      type: Object,
      default: () => ({})
    },
    registerId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fiskalySuccessful: false,
      processDone: false,
      loading: false,
      rules: {
        serial: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          },
          {
            min: 9,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 9,
              max: 10
            }),
            trigger: 'change'
          },
          {
            max: 10,
            message: this.$t('common.forms.rules.min_max_length', {
              min: 1,
              max: 10
            }),
            trigger: 'change'
          }
        ]
      },
      form: {
        // NOTE: Currently the serial number value is not being used since the Fiskaly API haven't yet implemented it. So we're just faking it as if it's being used, and once the Fiskaly API is ready it will be sent with the other data.
        serial: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration',
      clientAccount: 'Auth/getUser'
    }),
    buttonIsDisabled() {
      return this.form.serial === ''
    },
    tenant() {
      const config = this.isMultiTenantMode
        ? this.branchConfig
        : this.clientAccountConfiguration
      return get(config, 'tenant')
    },
    /* TODO CHECK */
    messageIconClass() {
      const iconClass = this.fiskalySuccessful
        ? 'el-icon-success'
        : 'el-icon-error'
      const colorClass = !this.fiskalySuccessful ? 'text-th-red' : undefined

      return [iconClass, colorClass]
    },
    messageHeadlineKey() {
      return this.fiskalySuccessful
        ? this.$t('pages.registers.fiskaly.result.headline.success')
        : this.$t('pages.registers.fiskaly.result.headline.fail')
    },
    messageTextKey() {
      return this.fiskalySuccessful
        ? this.$t('pages.registers.fiskaly.result.message.success')
        : this.$t('pages.registers.fiskaly.result.message.fail')
    },
    createOrganizationUrl() {
      const whitelabelOrg =
        this.$store.state.Auth.whitelabel === 'shore' ? 'shore' : 'tillhub'
      const stagingEnv =
        window.location.hostname.split('.')[0] === 'staging-dashboard'

      const whitelabel = `${whitelabelOrg}${
        process.env.NODE_ENV === 'production' && !stagingEnv ? '' : '-dev'
      }`
      return `https://europe-west1-tillhub-fiskaly.cloudfunctions.net/tillhub-fiskaly-dev-first/${whitelabel}/organizations/`
    },
    isMultiTenantMode() {
      return (
        get(this.clientAccountConfiguration, 'metadata.fiskaly_mode') ===
        'multitenant'
      )
    },
    fiscalOrganizationId() {
      const config = this.isMultiTenantMode
        ? this.branchConfig
        : this.clientAccountConfiguration
      return get(config, 'metadata.fiskaly.organization_id')
    },
    address() {
      return this.isMultiTenantMode
        ? this.branch?.addresses?.find(({ type }) => type === 'billing') || {}
        : get(this.tenant, 'address')
    }
  },
  methods: {
    preventEnterKey(e) {
      e.preventDefault()
      this.submit()
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.fiscalOrganizationId
            ? this.getTSSId(this.fiscalOrganizationId)
            : this.createOrganization()
        }
      })
    },
    async createOrganization() {
      const branch_id = this.isMultiTenantMode ? this.branch.id : undefined

      this.loading = true
      const params = {
        client_id: this.clientAccount,
        branch_id,
        tenant: {
          company_name: get(this.tenant, 'company_name'),
          address: this.address,
          tax_number: get(this.tenant, 'tax_number'),
          vat_id: get(this.tenant, 'vat_id')
        }
      }

      try {
        const {
          data: { data: organization = {} }
        } = await this.$axios.post(this.createOrganizationUrl, params)

        if (organization && organization._id) {
          await this.getTSSId(organization._id)
          await this.$store.dispatch('Config/getClientAccountConfiguration')
        } else {
          throw new Error('No Organization created')
        }
      } catch (err) {
        this.$logException(err)
      }

      this.processDone = true
      this.loading = false
    },
    async getTSSId(organizationId) {
      this.loading = true
      const params = {
        clientAccount: this.clientAccount,
        register: this.registerId
      }
      try {
        await this.$axios.post(
          `${this.createOrganizationUrl}${organizationId}/tss?version=2`,
          params
        )
        this.fiskalySuccessful = true
        this.$emit('close-enabled', true)
      } catch (err) {
        this.$logException(err)
      } finally {
        this.loading = false
        this.processDone = true
      }
    },
    saveTSE() {
      this.$emit('finish')
    }
  }
}
</script>

<style scoped>
.el-icon-error,
.el-icon-success {
  font-size: 24px;
}

.el-icon-success {
  color: var(--color-complete);
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.45s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  left: 50%;
  opacity: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 100%;
}
</style>
